/* eslint-disable */

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/3">
        <vx-card class="mb-base" title="Website">
          <h1 class="mb-8 text-center font-bold bg-primary rounded p-8">RPA CHALLENGE</h1>
          <div class="grid grid-cols-2 gap-4">
            <vs-input class="w-full" label-placeholder="Email" value=" " />
            <vs-input class="w-full" label-placeholder="Role in Company" value=" " />
            <vs-input class="w-full" label-placeholder="Company Name" value=" " />
            <vs-input class="w-full" label-placeholder="Email" value=" " />
            <vs-input class="w-full" label-placeholder="First Name" value=" " />
            <vs-input class="w-full" label-placeholder="Last Name" value=" " />
            <vs-input class="w-full" label-placeholder="Phone Number" value=" " />
            <vs-input class="w-full" label-placeholder="Address" value=" " />
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-2/3">
        <vx-card class="mb-base" title="UI Explorer">
          <h1 class="mb-8 text-center font-bold bg-primary rounded p-8">EXPLORER</h1>
          <div class="vx-row">
            <div class="vx-col w-3/4">
              <div v-for="item in selector_edits" :key="item.name" class="my-3">
                <div class="flex mb-1 mt-4">
                  <vs-switch v-model="item.selected" class="self-center mr-2" />
                  <h3 class="ml-2 break-all">{{ item.name }}</h3>
                </div>
                <small v-if="item.score === false" class="text-danger font-semibold">This answer is incorrect</small>
                <small v-if="item.score === true" class="text-success font-semibold">This answer is correct</small>
              </div>
            </div>
            <div class="vx-col w-1/4">
              <div class="border-white border-2 border-solid rounded p-4">
                <h1 class="text-center mb-4">Unselected Items</h1>
                <div class="grid grid-cols-2 gap-4">
                  <h4 v-for="item in unselected_items" :key="item">{{ item }}</h4>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      selector_edits: [
        { selected: false, name: '&lt; htmlapp=&#39;msedge.exe&#39;title=&#39;Rpa Challenge&#39;/&gt;', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&#39;tag=&#39;BODY&#39;/&gt;', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&#39;tag=APP-RO', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&#39;tag-', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap', score: null, answer: false },
        { selected: false, name: '&lt;webctrl aaname=&#39;First Name&#39; tag=&#39;LABEL&#39;/&gt;', score: null, answer: true },
        { selected: false, name: '&lt;nav up=&#39;1’? /&gt;', score: null, answer: true },
        { selected: false, name: '&lt;webctrl class=&#39;ng-untouched ng-pristine ng-invalid&#39; pa', score: null, answer: true },
      ],

      unselected_items: [
        'class',
        'ng-untouched',
        'css-selector',
        'body',
        'id',
        'choXw',
        'isleaf',
        '1',
        'name',
        'choXw',
        'parentclass',
        'ng-untouched',
        'tag',
        'INPUT',
        'title',
        'Rpa Challenge',
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.selector_edits.forEach((field, field_i) => {
        if (field.selected === field.answer) {
          this.selector_edits[field_i].score = true;
          totalScore++;
        } else {
          this.selector_edits[field_i].score = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  created() {},
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.Drag239834441344614400 {
  font-size: 23px;
}
</style>
